import { Box, Button, Flex, Heading, Stack, Tag } from "@chakra-ui/react";
import { InformationCircleIcon } from "@heroicons/react/solid";
import { ITopTag } from "./contentList";

interface IContentListHeroAreaProps {
  showTagArea: boolean;
  setShowTagArea: any;
  showInfoPannel: any;
  topTags: ITopTag[];
}

export const ContentListHeroArea = ({ setShowTagArea, showTagArea, showInfoPannel, topTags }: IContentListHeroAreaProps) => {
  return (
    <>
      <Box
        as={"section"}
        bg={"bg-surface"}
        bgImage={"/img/h-3.jpg"}
        backgroundPosition={"center"}
        backgroundSize={"cover"}
        minH={{ base: "260px", md: "360px" }}
      >
        <Flex
          flexDir={"column"}
          justifyContent={"center"}
          width={"full"}
          minH={{ base: "260px", md: "360px" }}
          height={"full"}
          bgGradient={"linear(to-r, blackAlpha.600, transparent)"}
          backdropFilter={"auto"}
          backdropBlur={"6px"}
        >
          <Stack spacing={{ base: "8", md: "10" }}>
            <Stack spacing={{ base: "4", md: "5" }} align={"center"}>
              <Heading
                size={"md"}
                color={"white"}
                fontSize={["32px", "32px", "40px", "48px"]}
                fontFamily="Bitter"
                fontWeight={600}
                display={"flex"}
                gap={2}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Box
                  w={[16, 16, 16, 20]}
                  h={[12, 14, 14, 16]}
                  bgImage={"/img/VIL_Logo_negativ_Bildmarke.svg"}
                  backgroundPosition={"center"}
                  backgroundRepeat="no-repeat"
                  backgroundSize={"contain"}
                ></Box>
                Mediathek
                <Button color={"white"} variant={"ghost"} onClick={() => showInfoPannel(1)}>
                  <InformationCircleIcon className="w-6 h-6" />
                </Button>
              </Heading>

              <Flex
                flexWrap={"wrap"}
                gap={4}
                justifyContent={"center"}
                maxW={{ base: "xl", sm: "2xl", lg: "2xl" }}
                boxShadow={"inner"}
                transition={"all 0.5s ease-in-out"}
              >
                {topTags.length > 0 &&
                  topTags.map((categoryFilter, index) => (
                    <Tag
                      borderRadius={"2xl"}
                      key={index}
                      px={[2, 2, 4, 4]}
                      bg={"white"}
                      color={"black"}
                      size={["sm", "sm", "md", "md"]}
                      minH={["24px", "24px", "32px", "32px"]}
                      fontWeight={600}
                    >
                      {categoryFilter.name}
                      <Box
                        p={1}
                        textAlign={"center"}
                        minW={"25px"}
                        fontWeight={700}
                        ml={3}
                        borderRadius={"4px"}
                        color={"darkBlue.500"}
                      >
                        {categoryFilter.counter}
                      </Box>
                    </Tag>
                  ))}
              </Flex>
              {topTags.length > 0 && (
                <Button
                  borderRadius={"2xl"}
                  onClick={() => {
                    setShowTagArea(!showTagArea);
                  }}
                  maxH={"32px"}
                >
                  # Suche
                </Button>
              )}
            </Stack>
          </Stack>
        </Flex>
      </Box>
    </>
  );
};
