import React from "react";
import styles from "./loadingDots.module.css";
interface ILoadingDotsProps {
  dotSize?: "small" | "default" | "big";
}
export const LoadingDots: React.FC<ILoadingDotsProps> = ({ dotSize = "default" }) => {
  const returnDotSize = () => {
    switch (dotSize) {
      case "small":
        return styles.smallDot;
      case "default":
        return styles.dot;
      case "big":
        return styles.dot;
    }
  };

  return (
    <span className={styles.dotsContainer}>
      <span className={returnDotSize()}>.</span>
      <span className={returnDotSize()}>.</span>
      <span className={returnDotSize()}>.</span>
    </span>
  );
};
