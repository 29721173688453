import React, { useEffect, useState } from "react";
import { AspectRatio, Link, Box, HStack, Tooltip, Image, Skeleton, Tag, Text, Center, Flex } from "@chakra-ui/react";
import { IContent, ILibraryContent, IMarketPlaceContent, IMSBContent, IPreviewFile } from "../../../../models/content";
import { CubeIcon, GlobeAltIcon } from "@heroicons/react/outline";
import { PhotographIcon, VideoCameraIcon } from "@heroicons/react/solid";
import { IContentCategory } from "../../../../models/contentCategory";
import { FaShare } from "react-icons/fa";
import { useAuth } from "../../../../provider/authentication";
import { VIL_OWNER_ID } from "../../../../utils/helper";

type ContentCardProps = {
  content: IContent | IMarketPlaceContent | IMSBContent;
  isMarketplace?: boolean;
};

export const ContentCard: React.FC<ContentCardProps> = ({ content, isMarketplace = false }) => {
  const [sortedTags, setSortedTags] = useState([]);
  const { user } = useAuth();
  const [hovered, setHovered] = useState(false);

  const returnIsLibraryContent = (content: IContent | IMarketPlaceContent | IMSBContent): content is ILibraryContent => {
    return !("arixId" in content);
  };
  const returnIsMSBContent = (content: IContent): content is IMSBContent => {
    return "arixId" in content;
  };

  const licenseIsInvalid = () => {
    if ("arixId" in content) {
      if (content.arixExpiryDate && content.arixExpiryDate == "2099-12-31") {
        //infinite license
        return false;
      } else {
        const expiryDate = new Date(content.arixExpiryDate);
        const today = new Date(new Date().setHours(0, 0, 0, 0));
        const res = expiryDate < today;
        return res;
      }
    } else {
      return false;
    }
  };

  const userOrgaId = user?.firstOrganisation?.id;

  const publicContentIds = [userOrgaId, VIL_OWNER_ID];

  let previewVideo: IPreviewFile | null = null;

  const styles = {
    image: {
      transition: "all 1.2s ease-in-out",
      transform: hovered ? "scale(1.1) translate(0%, 0%)" : "scale(1)",
      borderRadius: "md"
    },
    video: { transition: "opacity 1.2s ease-in-out" }
  };

  useEffect(() => {
    if (content) {
      if (returnIsMSBContent(content)) {
        setSortedTags(content.arixTags.slice(0, 2).sort((a: string, b: string) => a.localeCompare(b)));
      } else {
        setSortedTags(
          content.contentCategories.sort((a: IContentCategory, b: IContentCategory) => a.name.localeCompare(b.name))
        );
      }
    }
  }, [content]);

  if (content) {
    const options: Intl.DateTimeFormatOptions = { year: "numeric", month: "long", day: "numeric" };
    const jsDateCreatedAt = content.createdAt ? new Date(content.createdAt) : new Date();
    const tooltipLabel = jsDateCreatedAt.toLocaleDateString("de-DE", options);
    const previewFile = content.previewFiles && content.previewFiles.length > 0 ? content.previewFiles![0] : undefined;

    let localPreviewVideo = content.previewFiles?.filter((file: IPreviewFile) => {
      return file.fileType === "video";
    });
    if (localPreviewVideo && localPreviewVideo?.length > 0) {
      previewVideo = localPreviewVideo[0];
    }

    const renderTagName = (category: any) => {
      if (category.name) return category.name;

      return category;
    };

    const renderImage = () => {
      if (previewFile?.file) {
        return <Image src={previewFile.file} alt={content.title} draggable={"false"} borderRadius={"md"} style={styles.image} />;
      }
      return (
        <Box width={"full"} backgroundColor={"gray.50"} borderRadius={"md"} style={styles.image}>
          <Box width={"full"}>
            <Center color={"darkBlue.500"}>
              <PhotographIcon className={"w-1/2 h-auto object-center m-auto"} />
            </Center>
          </Box>
        </Box>
      );
    };
    const renderVideo = () => {
      if (previewVideo) {
        return <video style={styles.video} autoPlay muted src={previewVideo.file ?? ""}></video>;
      }
      return <></>;
    };

    const renderPreview = () => {
      if (hovered && previewVideo) {
        return renderVideo();
      }
      return renderImage();
    };

    return (
      <Link
        href={returnIsMSBContent(content) ? `/msb_library/${content.id}` : `/library/${content.id}`}
        borderRadius="3xl"
        transition="all 1s"
        _hover={{
          textDecoration: "none",
          boxShadow: "0 2px 0 #2d465f, -0.1875rem -0.1875rem 0 0.125rem #2d465f, 0 0 0 0.25rem #28beff"
        }}
      >
        <Box
          h={"100%"}
          pb={2}
          maxWidth={"container.md"}
          color={"#2D445E"}
          position={"relative"}
          borderRadius={"3xl"}
          overflow={"hidden"}
        >
          <Box>
            <AspectRatio
              p={0.5}
              borderBottomRadius="3xl"
              filter={licenseIsInvalid() ? "grayscale(90%)" : ""}
              ratio={4 / 3}
              position={"relative"}
              style={{ overflow: "hidden", position: "relative" }}
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
            >
              {renderPreview()}
            </AspectRatio>

            <Box color={"black"} position={"absolute"} top={2} left={2} display={"flex"}>
              <Flex flexDir={"column"} gap={2}>
                {returnIsMSBContent(content) && (
                  <Tooltip label="Inhalt der Bildungsmediathek NRW">
                    <Box borderRadius="3xl" bgColor={"white"}>
                      <Image w={10} h={10} src="/img/bildungsmediathek-nwr-logo_short.svg"></Image>
                    </Box>
                  </Tooltip>
                )}
              </Flex>
            </Box>
            <Box color={"black"} position={"absolute"} top={2} right={2} display={"flex"}>
              <Box mr={2} justifyContent={"flex-end"}>
                {content.isNew && (
                  <Tooltip label={tooltipLabel}>
                    <Tag borderRadius={"3xl"} colorScheme={"green"} size={"md"}>
                      {"neu".toUpperCase()}
                    </Tag>
                  </Tooltip>
                )}
              </Box>
              <Flex flexDir={"column"} gap={2}>
                <Box ml={"auto"} bg={"white"} p={1} borderRadius={"3xl"} w={6} h={6}>
                  {content.contentType === "app" && (
                    <Box title="Anwendung">
                      <CubeIcon />
                    </Box>
                  )}
                  {content.contentType === "video" && (
                    <Box title="Video">
                      <VideoCameraIcon />
                    </Box>
                  )}
                  {content.contentType === "webApp" && (
                    <Box title="Web App">
                      <GlobeAltIcon />
                    </Box>
                  )}
                </Box>
                {returnIsLibraryContent(content) && content.owner && !publicContentIds.includes(content.owner.id) && (
                  <Flex
                    alignContent={"center"}
                    justifyContent="center"
                    title="Geteilter Inhalt"
                    bg={"white"}
                    p={1}
                    borderRadius={"3xl"}
                    w={6}
                    h={6}
                  >
                    <FaShare className="w-3 h-4" />
                  </Flex>
                )}
              </Flex>
            </Box>
          </Box>
          <Box px="2">
            <Box mt={2} justifyContent={"space-between"} color={isMarketplace ? "white" : "darkBlue.500"}>
              <Text
                title={content.title}
                fontSize={{ base: "md", md: "lg" }}
                fontWeight={600}
                sx={{
                  display: "-webkit-box",
                  overflow: "hidden",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2,
                  textOverflow: "ellipsis"
                }}
              >
                {content?.title}
              </Text>
              {returnIsLibraryContent(content) && (
                <>
                  {content.licensor && content.licensor.length > 0 && (
                    <Text fontSize={{ base: "sm", md: "md" }} fontWeight={400}>
                      {content.licensor}
                    </Text>
                  )}
                </>
              )}
            </Box>
            <HStack mt={1} flexWrap="wrap" spacing={1}>
              {sortedTags?.map((category: IContentCategory, index) => (
                <Tag
                  key={index}
                  mt={1}
                  mr={2}
                  bgColor={isMarketplace ? "darkBlue.700" : "darkBlue.500"}
                  color={"white"}
                  mb={2}
                  fontSize="14px"
                  borderRadius="3xl"
                  colorScheme={"facebook"}
                  size={{ base: "sm", lg: "md" }}
                >
                  {renderTagName(category)}
                </Tag>
              ))}
            </HStack>
          </Box>
        </Box>
      </Link>
    );
  }

  return <Skeleton />;
};
