import { Option } from "react-multi-select-component";
import { BasicObject } from "../constants";
import { IContentPartner } from "./contentPartner";
export enum ContentType {
  APPLICATION = "app",
  VIDEO = "video",
  WEBAPP = "webApp"
}

export enum ContentPrivacy {
  Exclusive = "exclusive",
  Public = "public",
  Private = "private"
}

export enum PreviewFileType {
  Image = "image",
  Video = "video"
}

export enum VideoType {
  _180 = "180",
  _360 = "360",
  _3D180_LR = "3D180_LR",
  _3D360_LR = "3D360_LR",
  _Plane = "plane",
  _3D180_TB = "3D180_TB",
  _3D360_TB = "3D360_TB"
}

export enum AgeRecommendation {
  FSK0 = "0",
  FSK12 = "12",
  FSK16 = "16",
  FSK18 = "18"
}

export enum AppPlayMode {
  SINGLE_PLAYER = "singleuser",
  MULTI_PLAYER = "multiuser",
  SINGLE_MULTI_PLAYER = "single- and multiuser"
}

export enum AppPlayArea {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large"
}

export enum AppUserPosition {
  STANDING = "standing",
  SITTING = "sitting",
  STANDING_AND_SITTING = "standing and sitting"
}

export interface SupportedLanguage {
  id: number;
  name: string;
}

export enum VRDevice {
  PICOG24K = "Pico G24K",
  PICONEO3 = "Pico Neo 3 Pro",
  PICOG3 = "Pico G3",
  PICO4 = "Pico 4 Enterprise",
  PICO4ULTRA = "Pico 4 Ultra Enterprise",
  META2 = "Meta Quest 2",
  META3 = "Meta Quest 3"
}

export interface CompatibleVRDevice {
  id: number;
  modelName: string;
}

export interface DeviceTypes {
  id: number;
  name: string;
  producer: string;
  maxSizeGB: number;
  dimensions: number;
}

export const DEVICE_TYPES: DeviceTypes[] = [
  { id: 3, name: "4 Enterprise", producer: "Pico", maxSizeGB: 225, dimensions: 6 },
  { id: 4, name: "G3", producer: "Pico", maxSizeGB: 108, dimensions: 3 },
  { id: 2, name: "Neo 3 Pro", producer: "Pico", maxSizeGB: 227, dimensions: 6 },
  { id: 1, name: "G24K", producer: "Pico", maxSizeGB: 49, dimensions: 3 },
  { id: 6, name: "Quest 3", producer: "Meta", maxSizeGB: 128, dimensions: 6 },
  { id: 7, name: "4 Ultra Enterprise", producer: "Pico", maxSizeGB: 128, dimensions: 6 }
  // { id: 5, name: "Quest 2", producer: "Meta", maxSizeGB: 128, dimensions: 6 }
];

export const COMPATIBLE_DEVICE_OPTIONS: Option[] = [
  {
    value: 1,
    label: VRDevice.PICOG24K
  },
  {
    value: 2,
    label: VRDevice.PICONEO3
  },
  {
    value: 4,
    label: VRDevice.PICOG3
  },
  {
    value: 7,
    label: VRDevice.PICO4ULTRA
  },
  {
    value: 3,
    label: VRDevice.PICO4
  },
  {
    value: 5,
    label: VRDevice.META2
  },
  {
    value: 6,
    label: VRDevice.META3
  }
];

export interface ContentPermission {
  id: number;
  name: string;
}

export interface IContent {
  id?: number;
  title?: string;
  shortDescription?: string;
  createdAt?: Date;
  contentType?: ContentType;
  diffInDays?: number;
  contentCategories?: any | Array<Number | String>;
  isNew?: boolean;
  previewFiles?: IPreviewFile[];
  contentPreviewFile?: IPreviewFile[];
  description?: string;
  offlineSupport?: boolean;
  playtime?: number;
  origin?: "MSB" | "VIL";
  owner?: any;
  videoLength?: string;
  ageRecommendationRangeLower?: number;
  ageRecommendationRangeUpper?: number;
  ageRecommendation?: number;
  accountNeeded?: boolean;
  compatibleDevices?: CompatibleVRDevice[] | any[];
  contentPermissions?: any;
  languages?: SupportedLanguage[] | any[];
  playMode?: AppPlayMode;
  playArea?: AppPlayArea;
  position?: AppUserPosition;
  updatedAt?: string;
}
export interface ILibraryContent extends IContent {
  version?: string;
  uuid?: string;
  file?: string;
  webUrl?: string;
  fileSize?: number;
  fileName?: string;
  licensor?: string;
  sharedWith?: BasicObject[];
  licensorLink?: string;
  contentPrivacy?: ContentPrivacy;
  packageName?: string;
  videoType?: VideoType | string;
}

export interface IMarketPlaceContent extends IContent {
  contentPartner?: IContentPartner;
  videoLength?: string;
}

export interface IMSBContent extends Omit<IContent, "contentPreviewFile"> {
  additionalLicenseInfo: string;
  arixAddressee: string[];
  arixExpiryDate: string;
  arixFileUrl: string;
  arixFsk: string;
  arixId: number;
  arixLanguage: string;
  arixLicensor: string;
  arixLicensorLink: string;
  arixLicenseType: string;
  arixAdditionalLicenseInfo: string;
  arixPreviewImages: [];
  arixSubtitle: string;
  arixSeriesTitle: string;
  arixSubjectAreas: string[];
  arixTags: [];
  arixType: string;
}
export interface IPreviewFile {
  id: number;
  file: string;
  fileType: PreviewFileType;
  order: number;
}
